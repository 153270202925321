import React, { useRef } from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Carousel } from './styled'
import { LayoutCentering } from '..'

const CustomCarousel = ({ children, lightControls = false, hideArrows = false, ...rest }) => {
  const carouselRef = useRef()

  return (
    <Carousel>
      <Carousel.List ref={carouselRef} {...rest}>
        {children}
      </Carousel.List>

      {!hideArrows && (
        <LayoutCentering relative>
          <Carousel.Prev light={lightControls} onClick={() => carouselRef.current.prev()}>
            <ArrowLeftOutlined />
          </Carousel.Prev>

          <Carousel.Next light={lightControls} onClick={() => carouselRef.current.next()}>
            <ArrowRightOutlined />
          </Carousel.Next>
        </LayoutCentering>
      )}
    </Carousel>
  )
}

export default CustomCarousel
