import React from 'react'
import { Grid, Space, Divider, Typography, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { Layout, Logotype, FooterUnder, Feedback, RadicalLogo } from './styled'
import { Ellipse } from '..'
import { images } from '../../../assets'
import { theme } from '../../../styles'

const { useBreakpoint } = Grid
const { Text } = Typography

const Footer = () => {
  const screens = useBreakpoint()

  return (
    <Layout>
      <Layout.SemiRingTop>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={screens.lg ? 8.8 : 6} rotate={-45} />
      </Layout.SemiRingTop>

      <Layout.SemiRingBottom>
        <Ellipse type='semiring' color={theme.colors.green[200]} size={13.8} rotate={-45} />
      </Layout.SemiRingBottom>

      <Layout.Inner>
        <Link to='/'>
          <Logotype src={images.Logotype} />
        </Link>

        <Layout.Panel>
          <Layout.Panel.Label>Head office</Layout.Panel.Label>

          <Layout.Panel.Data>
            <Layout.Panel.Icon src={images.iconLocation} /> 102 Chapel Lane, Wigan WN3 4HG
          </Layout.Panel.Data>

          <Layout.Panel.Data>
            <Layout.Panel.Icon src={images.iconPhone} /> <a href='tel:01942826500'>01942 826 500</a>
          </Layout.Panel.Data>

          <Layout.Panel.Data>
            <Layout.Panel.Icon src={images.iconEmail} />
            <a href='mailto:info@estateresearchlearninghub.co.uk'>info@estateresearchlearninghub.co.uk</a>
          </Layout.Panel.Data>
        </Layout.Panel>

        <Layout.Panel>
          <Layout.Panel.Label>London office</Layout.Panel.Label>

          <Layout.Panel.Data>
            <Layout.Panel.Icon src={images.iconLocation} /> 150 Minories, London EC3N 1LS
          </Layout.Panel.Data>

          <Layout.Panel.Data>
            <Layout.Panel.Icon src={images.iconPhone} /> <a href='tel:02073475140'>020 7347 5140</a>
          </Layout.Panel.Data>

          <Layout.Panel.Data>
            <Layout.Panel.Icon src={images.iconEmail} />
            <a href='mailto:info@estateresearchlearninghub.co.uk'>info@estateresearchlearninghub.co.uk</a>
          </Layout.Panel.Data>
        </Layout.Panel>

        <Feedback>
          <Feedback.Title>Have any feedback?</Feedback.Title>

          <Feedback.Wrapper>
            <Feedback.Icon src={images.iconFeedbackLike} />
            <Text>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              We'd love to hear it.
              <br />
              Fill out our <Link to='/feedback'>feedback form</Link>
            </Text>
          </Feedback.Wrapper>
        </Feedback>
      </Layout.Inner>

      <FooterUnder>
        <Row align='middle'>
          <Col flex={screens.lg ? 'auto' : '100%'}>
            <Space
              direction={screens.lg ? 'horizontal' : 'vertical'}
              className='split-wrapper'
              split={<Divider type='vertical' style={{ borderColor: theme.colors.gray[100] }} />}
            >
              <Space split={<Divider type='vertical' style={{ borderColor: theme.colors.gray[100] }} />}>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </Space>

              <Space
                className='split-bottom'
                split={<Divider type='vertical' style={{ borderColor: theme.colors.gray[100] }} />}
              >
                <span>Estate Research Ltd Company No. 08765186</span>
                <span>Estate Research PC Ltd Company No. 10253079</span>
              </Space>
            </Space>
          </Col>
          <Col style={{ marginTop: screens.lg ? 0 : 14 }}>
            <RadicalLogo href='//radicalwebdesign.co.uk' rel='noopener noreferrer' target='_blank'>
              <span>Website by</span>
              <img src={images.radicalLogo} alt='' />
            </RadicalLogo>
          </Col>
        </Row>
      </FooterUnder>
    </Layout>
  )
}

export default Footer
